import React from 'react';
import {string, bool, oneOf, objectOf, any} from "prop-types";
import Image from "../ImageQueries/GeneralImages";
import Title from "../Title";

const Hero = (props) => {
    const {className, heroImage, heroTitle, heroSubtext, adjust} = props
    return (
        <div className='md:mt-auto mt-24 '>
            <Image imageName={heroImage} className={`w-full min-h-[400px] 2xl:mx-auto adjust-img`} id='#adjust-img' />
            {heroTitle && (
                <div className='grid xl:grid-cols-2 sm:grid-cols-1 lg:px-[122px] md:px-12 py-16 px-[16px] bg-black lg:mx-[47px] md:mx-8 mx-0 md:rounded-b-lg sm:rounded-none content-center items-center lg:max-w-[1346px] super-large-center'>
                    <Title level={2} withBlueSymbol symbol='.' className='text-white md:mr-24 sm:mr-12 text-[42px] leading-[140%] lg:leading-[140%]'>{heroTitle}</Title>
                    <p className='font-sans text-black-500 text-[20px] leading-[160%]'>{heroSubtext}</p>
                </div>
            )}
        </div>
    );
};
Hero.propTypes = {
    className: string,
    heroImage: objectOf(any),
    heroTitle: string,
    heroSubtext: string,
    adjust: bool
};

Hero.defaultProps = {
    className: '',
    heroImage: '',
    heroTitle: '',
    heroSubtext: '',
    adjust: false,
};

export default Hero;
