import React from 'react';
import { string, objectOf, any, arrayOf } from 'prop-types';
import Title from '../Title';
import Image from '../ImageQueries/GeneralImages';
import CtaSecondary from '../Buttons/Secondary';

const ArticleCard = (props) => {
    const { title, short, images, idx, slug } = props;

    const image = images[0];

    return (
        <div className={idx % 2 !== 0 ? 'lg:mt-16 sm:mt-0' : 'mt-0'}>
            <a href={`/articles/${slug}`}>
                <Image imageName={image} className="rounded-[32px]" />
            </a>
            <a
                href={`/articles/${slug}`}
                className="no-underline text-black-200"
            >
                <Title
                    className="text-[24px] leading-[130%] mb-0"
                    level={3}
                    style={{ marginBottom: 0 }}
                >
                    {title}
                </Title>
            </a>
            <p className="font-sans mt-[16px] text-black-200 max-w-[600px] text-[16px] leading-[160%]">
                {short}
            </p>
            <CtaSecondary title="Read more" large target={`articles/${slug}`} />
        </div>
    );
};
ArticleCard.propTypes = {
    title: string,
    short: string,
    slug: string,
    images: arrayOf(any),
    ctaLink: objectOf(any),
};

ArticleCard.defaultProps = {
    title: '',
    short: '',
    slug: '',
    images: [],
    ctaLink: null,
};

export default ArticleCard;
