import React from 'react';
import { string, arrayOf, any } from 'prop-types';
import ArticleCard from './ArticleCard';

import CtaSecondary from '../Buttons/Secondary';
import Title from '../Title';

const Articles = (props) => {
    const { articles } = props;

    return (
        <div className="lg:py-32 lg:px-0 px-[16px] py-0 lg:pt-0 max-w-[1440px] lg:mx-[160px] xl:mx-auto xl:px-[160px]">
            <Title
                level={2}
                className="text-[42px] leading-[140%] mb-[60px]"
                withBlueSymbol
                symbol="."
            >
                What's going on at SpotOn?
            </Title>
            <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-16">
                {articles.map((article, idx) => (
                    <ArticleCard
                        {...article}
                        idx={idx}
                        key={`article_name_${article.title}`}
                    />
                ))}
            </div>
            <CtaSecondary
                className="mx-auto md:mt-16 mt-8 mb-[70px]"
                title="See all articles"
                large
                target="/articles"
            />
        </div>
    );
};

Articles.propTypes = {
    className: string,
    articles: arrayOf(any),
};

Articles.defaultProps = {
    className: '',
    articles: [],
};

export default Articles;
