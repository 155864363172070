import React from 'react';
import PropTypes, {string, bool, oneOf, objectOf, any} from "prop-types";
import Image from "../ImageQueries/GeneralImages";
import Title from "../Title";
import check from "../../images/svg/checkmark.svg";
import CtaSecondary from "../Buttons/Secondary";
import CtaPrimary from "../Buttons/Button";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import chevron from "../../images/svg/chevron_left.svg";

const Feature = (props) => {
    const Chevron = <img className="ml-2.5" alt="chevron icon" src={chevron} />;

    const {
        symbol,
        image,
        sectionTitle,
        subtext,
        list,
        ctaInfo,
        ctaPrime,
        isEven,
        name,
        position,
    } = props

    return (
        <>
            {isEven ? (
                <div className="grid xxl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 gap-x-[171px] gap-y-[13px] md:mb-24 mb-[80px]">
                    <Image
                        imageName={image}
                        className="box-border max-h-full rounded-[32px]"
                        alt=""
                    />
                    <div className="xl:place-self-center">
                        <Title
                            className="font-bold text-[32px] leading-[130%]"
                            style={{ marginBottom: 24}}
                            level={2}
                            symbol={symbol}
                            withBlueSymbol
                        >
                            {sectionTitle}
                        </Title>
                        <Title level={4} className='my-0 text-[18px] leading-[130%]' style={{marginBottom: 0, marginTop: 0}}>{name}</Title>
                        <p className='font-sans text-[16px] text-black-200 mb-[24px] mt-0'>{position}</p>
                        {subtext && (
                            subtext.split('\n').map((item, idx) => (
                                <p
                                    className={`font-sans text-black-200 xl:max-w-[448px] text-[16px] leading-[160%] ${idx + 1 === subtext.split('\n').length && 'mb-12' } ${idx === 0 && subtext.split('\n').length <= 1 ? 'mb-12' : 'mb-0'}`}
                                    style={{ fontWeight: 400, fontSize: 16}}
                                    key={`${sectionTitle}__${idx.toString()}__right`}
                                >
                                    {item}
                                </p>
                            ))
                        )}
                        {ctaInfo && (
                            <CtaSecondary
                                title={ctaInfo.title}
                                large
                                target={ctaInfo.target}
                            />
                        )}
                        {ctaPrime && (
                            <AnchorLink
                                to={ctaPrime.target}
                                className=' cta-p
        flex flex-row items-center
        px-6 py-2.5
        rounded-full border-0 cursor-pointer
        text-white font-bold bg-primary hover:bg-opacity-70 hover:text-white w-max font-sans no-underline'
                            >
                                {ctaPrime.title}
                                {Chevron}
                            </AnchorLink>
                        )}
                    </div>
                </div>
            ) : (
                <div className="grid xxl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 gap-x-[171px] gap-y-[13px] md:mb-24 mb-[80px] order-last">
                    <div className="xl:place-self-center">
                        <Title
                            className="font-bold text-[32px] leading-[130%]"
                            level={2}
                            style={{ marginBottom: 24}}
                            symbol={symbol}
                            withBlueSymbol
                        >
                            {sectionTitle}
                        </Title>
                        <Title level={4} className='my-0 text-[18px] leading-[130%]' style={{marginBottom: 0, marginTop: 0}}>{name}</Title>
                        <p className='font-sans text-[16px] text-black-200 mb-[24px] mt-0'>{position}</p>

                        {subtext && (
                            subtext.split('\n').map((item, idx) => (
                                <p
                                    className={`leading-8 font-thin text-black-200 font-sans xl:max-w-[448px] ${idx + 1 === subtext.split('\n').length && 'mb-12' } ${idx === 0 && subtext.split('\n').length <= 1 ? 'mb-12' : 'mb-0'}`}
                                    style={{ fontWeight: 400, fontSize: 16 }}
                                    id={`${sectionTitle}__${idx.toString()}__left`}
                                    key={`subtext_of_${sectionTitle}_${idx.toString()}`}

                                >
                                    {item}
                                </p>
                            ))

                        )}
                        {ctaInfo && (
                            <CtaSecondary
                                title={ctaInfo.title}
                                large
                                target={ctaInfo.target}
                            />
                        )}
                        {ctaPrime && (
                            <AnchorLink
                                to={ctaPrime.target}
                                className=' cta-p
        flex flex-row items-center
        px-6 py-2.5
        rounded-full border-0 cursor-pointer
        text-white font-bold bg-primary hover:bg-opacity-70 hover:text-white w-max font-sans no-underline'
                            >
                                {ctaPrime.title}
                                {Chevron}
                            </AnchorLink>                        )}
                    </div>
                        <Image
                            imageName={image}
                            className="box-border object-scale-down max-h-full xl:order-last order-first rounded-[32px]"
                            alt=""
                        />
                </div>
            )}
        </>
    );
};
Feature.propTypes = {
    sectionTitle: PropTypes.string,
    isEven: PropTypes.number,
    symbol: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.any),
    image: PropTypes.objectOf(any),
    subtext: PropTypes.string,
    ctaInfo: PropTypes.shape({
        target: PropTypes.string,
        title: PropTypes.string,
    }),
    ctaPrime: PropTypes.shape({
        target: PropTypes.string,
        title: PropTypes.string,
    }),
};

Feature.defaultProps = {
    className: '',
    symbol: ".",
    sectionTitle: "One solution for take-out, delivery, and more",
    subtext:
        "End the hassle of dealing with expensive third-party delivery services and entering orders manually into your POS. We make it easy for your guests to order directly from your website, Facebook page, and Google. With direct POS integration and pacing controls for online orders, you can boost sales and kitchen efficiency. Better yet, you keep your guest data so you can easily retarget guests to drive repeat visits.",
    image: "../../images/visuals/home-a.png",
    list: [],
    ctaInfo: null,
    ctaPrime: null,
    isEven: null,
};

export default Feature;
