import React from "react";
import Feature from "./Feature";
import Title from "../Title";
import PropTypes from "prop-types";
import "./LargeFeature.css";

const LargeFeatures = (props) => {
  const {
    style,
    sectionNavId,
    items,
      title,
  } = props;
  return (
    <section className="large-features 2xl:mx-auto lg:px-[160px] md:px-8 md:py-32 md:pb-32 pb-0 px-[16px] py-12 max-w-[1440px] " id={sectionNavId} style={style}>
      <Title level={2} symbol='.' className='text-center md:pb-12 pb-8 text-[42px] leading-[140%] ' withBlueSymbol>{title}</Title>
      {items.map((item, idx) => (
          <Feature key={item.sectionTitle} isEven={idx % 2} symbol='.' sectionTitle={item.sectionTitle} subtext={item.subtext} image={item.image} ctaPrime={item.ctaPrime} name={item.name} position={item.position}/>
      ))}
    </section>
  );
};

LargeFeatures.propTypes = {
  style: PropTypes.objectOf(PropTypes.any),
  sectionNavId: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string,
};
LargeFeatures.defaultProps = {
  style: {},
  sectionNavId: "",
  items: [],
  title: ''
};

export default LargeFeatures;
