import * as React from 'react';
import Layout from '../Components/Layout';
import HeadingDirective from '../Components/HeadingDirective';
import Hero from '../Components/Hero';
import { graphql } from 'gatsby';
import LargeFeature from '../Components/LargeFeature/LargeFeature';
import Offices from '../Components/Offices';
import Teams from '../Components/Teams';
import ArticleCards from '../Components/ArticleCards/ArticleCards';
import SEO from '../Components/SEO';

const IndexPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;
    return (
        <Layout>
            <SEO
                title={frontmatter.seo.title}
                description={frontmatter.seo.description}
                image={`https://pl.spoton.com/assets/${frontmatter.seo.image}`}
            />
            <HeadingDirective {...frontmatter.directive} />
            <Hero {...frontmatter.hero} adjust />
            <LargeFeature {...frontmatter.divisions} />
            <Teams presentation={frontmatter.teams} />
            <ArticleCards articles={frontmatter.articles} />
            <Offices imageArray={frontmatter.imageArray} />
        </Layout>
    );
};

export default IndexPage;

export const pageQuery = graphql`
    query IndexPage {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
            frontmatter {
                directive {
                    directive
                    symbol
                    subtext
                }
                seo {
                    title
                    description
                    image
                }
                hero {
                    heroTitle
                    heroSubtext
                    heroImage {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: WEBP
                                layout: FULL_WIDTH
                            )
                        }
                    }
                }
                divisions {
                    title
                    items {
                        image {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    formats: WEBP
                                    layout: FULL_WIDTH
                                )
                            }
                        }
                        sectionTitle
                        symbol
                        name
                        subtext
                        position
                        ctaPrime {
                            title
                            target
                        }
                    }
                }
                imageArray {
                    img {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: WEBP
                                layout: FULL_WIDTH
                            )
                        }
                    }
                }
                teams {
                    teamName
                    teamAbout
                    stack
                    teamMate
                    matePosition
                    img {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: WEBP
                                layout: FULL_WIDTH
                            )
                        }
                    }
                }
                articles {
                    title
                    short
                    slug
                    images {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: WEBP
                                layout: FULL_WIDTH
                            )
                        }
                    }
                }
            }
        }
    }
`;
